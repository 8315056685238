/* Option 2: Import via CSS */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    max-width: 980px;
    margin-inline: auto;
}

section {
    height: 100%;
    padding: 10px;
}

footer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #bbbbbb;
    padding: 10px;
    
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block: 10px;
    padding-block: 10px;
    border-bottom: 1px solid #bbbbbb;
}

.logo {
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.links {
    text-decoration: none;
    color: black;
    padding: 10px;
}

.active {
    background-color: #f5f1f1;
    border-radius: 10px;
}

.btn {
    padding: 10px;
    border: none;
    background-color: #1c59ac;
    color: white;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
}


.post-card {
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-bottom: 15px;
}

.post-card .title {
    padding-block: 10px;
    font-size: 1.2rem;
}

.post-card .post {
    padding-block: 4px 14px;
}

.post-card .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.controls .author {
    background-color: #f5f1f1;
    padding: 8px;
    border-radius: 5px;
}

.controls .delete {
    color: red;
    cursor: pointer;
}

.create-post {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-block: 15px;
}

.create-post .title {
    padding-block: 12px;
}

.create-post form {
    display: flex;
    flex-direction: column;
    width: 650px;
}

.create-post form input {
    outline: none; 
    font-size: 1rem;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.create-post form textarea {
    resize: none;
    height: 250px;
    margin-bottom: 14px;
    padding: 10px;
    font-size: 1rem;
    outline: none;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.create-post form button {
    background-color: #5fcc65;
    border: none;
    padding: 10px;
    font-size: 1rem;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}


.page-not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 80svh;
}

.page-not-found h3 {
    padding-block: 10px;
    font-size: 2rem;
}

.page-not-found img {
    width: 240px;
}

@media only screen and (max-width: 450px) {
    body{
        margin-inline: 20px;
    }
    .create-post form {
        width: 360px;
    }
}